/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "The goal of this video:"), " To create trust in a product. We show why the surfboard is worth its price and build the image of an authentic brand that pursues its own values with verve."), "\n", React.createElement(_components.p, null, "In the video, the viewer experiences Martin's everyday life, shares his emotions and thus builds a relationship with Martin, the Treemboards brand and the product."), "\n", React.createElement(_components.p, null, "You can find his products and more information on Martin's website:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.treemboards.de",
    title: "Treemboards Website"
  }, "www.treemboards.de")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
